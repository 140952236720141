import Layout from '@core/Layout'
import { LOCALSTORAGE_VARIABLE } from './consts'

export default class Tip extends Layout {
  constructor(options) {
    super({
      selector: '.tip-of-the-day',
    })

    this.options = {
      text: '',
      page: '',
      ...options,
    }

    this.init()
  }

  init() {
    this.toggleHidden(this.$target, Boolean(this.checkVisibility().hideTip))
    this.bindEvents()
  }

  bindEvents() {
    this.$target.find('.close-icon').on('click', () => {
      this.toggleHidden(this.$target, true)
      localStorage.setItem(`${LOCALSTORAGE_VARIABLE}${this.options.page}`, '1')
    })
  }

  checkVisibility() {
    return {
      hideTip: localStorage.getItem(`${LOCALSTORAGE_VARIABLE}${this.options.page}`),
    }
  }
}
